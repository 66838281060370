@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    background: #ffe6ff;
    color: #282828;
    --secondary-text: #414042;
    --primary-color: #78007a;

    text-align: center;
}

body {
    /* background: #ffe6ff; */
    max-width: 480px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.bg-primary {
    background-color: var(--primary-color);
}

/* Scroll Bar Styling */
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

::-webkit-scrollbar-track:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

.shadow-all-side {
    box-shadow: 0px 0px 10px 2px rgba(50, 50, 93, 0.11);
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease-out; /* Adjust duration as desired */
}
